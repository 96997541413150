<template>
  <el-dialog
    title="Change Customer Gardener"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <el-form ref="form" :model="form" label-position="top" v-loading="loading">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Gardener"
            prop="gardener_id"
            :rules="validateField()"
          >
            <el-select
              v-model="form.gardener_id"
              filterable
              placeholder="Select gardener"
            >
              <el-option
                v-for="(gardener, index) in gardeners"
                :key="index"
                :label="gardener.name"
                :value="gardener.id"
                :disabled="gardener.id === form.current_gardener_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="primary"
        :loading="changing"
        :disabled="!form.gardener_id"
        @click="change"
        >Save changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerGardenerChange",
  props: {
    show: Boolean,
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        current_gardener_id: null,
        gardener_id: null,
      },
      changing: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
    gardenerName() {
      const gardener = this.gardeners.filter((gardener) => {
        return gardener.id === this.form.gardener_id;
      });
      return gardener.length ? gardener[0].name : "";
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.current_gardener_id = this.customer.gardener_id;
        if (!this.gardeners.length) {
          this.getGardeners();
        }
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },

    getGardeners() {
      this.loading = true;
      this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
        this.loading = false;
      });
    },
    change() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.changing = true;

        const payload = {
          gardener_id: this.form.gardener_id,
        };

        customer
          .gardener(this.customer.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.changing = false;
              this.$message.success(response.data.message);
              this.$emit("success", {
                gardener_id: this.form.id,
                gardener: this.gardenerName,
              });
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.changing = false;
            this.$message.error(error.response.data.message);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
